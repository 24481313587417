import React from "react";

const TrackingTags = () => (
  <>
    {/**
     * Tracking code is backed up on backup.js
     */}
  </>
);

export default TrackingTags;
