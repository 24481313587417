import React from "react";

import "./main.scss";
const NoScript = require("react-noscript");

const Layout = ({ children }) => (
  <>
    {children}

    <NoScript></NoScript>
  </>
);

export default Layout;
